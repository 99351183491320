import React, { useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import QRCode from 'qrcode';
import './User.css';

const User = () => {
  const [website, setWebsite] = useState('');
  const [name, setName] = useState('');
  const [qrCodeUrl, setQrCodeUrl] = useState('');
  const canvasRef = useRef(null);

  const generateQRCode = () => {
    if (website && name) {
      QRCode.toDataURL(`${website}?name=${encodeURIComponent(name)}`)
        .then((url) => {
          setQrCodeUrl(url);
          const canvas = canvasRef.current;
          if (canvas) {
            QRCode.toCanvas(canvas, `${website}?name=${encodeURIComponent(name)}`);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    } else {
      alert('Please enter both a website URL and your name');
    }
  };

  return (
    <div className="user-container">
      <h2>User Page - QR Code Generator</h2>
      <input
        type="text"
        value={website}
        onChange={(e) => setWebsite(e.target.value)}
        placeholder="Enter website URL"
      />
      <input
        type="text"
        value={name}
        onChange={(e) => setName(e.target.value)}
        placeholder="Enter your name"
      />
      <button onClick={generateQRCode}>Generate QR Code</button>
      {qrCodeUrl && (
        <div className="qr-code">
          <canvas ref={canvasRef}></canvas>
          <a href={qrCodeUrl} download={`${name}_qrcode.png`}>Download QR Code</a>
        </div>
      )}
      <Link to="/" className="back-link">
        Back to Home
      </Link>
    </div>
  );
};

export default User;
