import React from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import User from './components/User';
import Admin from './components/Admin';
import Home from './components/Home'; // Assuming you have a Home component

const App = () => {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/user" element={<User />} />
          <Route path="/admin" element={<Admin />} />
          <Route path="/" element={<Home />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
