import React, { useEffect, useRef, useState } from 'react';
import jsQR from 'jsqr'; // Make sure jsQR library is properly installed and imported
import { Link } from 'react-router-dom';
import './Admin.css';

const Admin = () => {
  const [scannedData, setScannedData] = useState('');
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const streamRef = useRef(null);

  useEffect(() => {
    const startScanner = async () => {
      try {
        const video = videoRef.current;
        const canvas = canvasRef.current;

        if (!video || !canvas) return;

        const constraints = {
          video: {
            facingMode: 'environment' // Use the back camera if available
          }
        };

        const stream = await navigator.mediaDevices.getUserMedia(constraints);
        video.srcObject = stream;
        streamRef.current = stream;

        video.play()
          .catch(err => console.error('Error playing video:', err));

        const tick = () => {
          if (video.readyState === video.HAVE_ENOUGH_DATA) {
            canvas.width = video.videoWidth;
            canvas.height = video.videoHeight;
            const context = canvas.getContext('2d');
            context.drawImage(video, 0, 0, canvas.width, canvas.height);
            const imageData = context.getImageData(0, 0, canvas.width, canvas.height);
            const code = jsQR(imageData.data, imageData.width, imageData.height);
            if (code) {
              setScannedData(code.data);
            }
          }
          requestAnimationFrame(tick);
        };

        requestAnimationFrame(tick);
      } catch (error) {
        console.error('Error accessing the camera:', error);
      }
    };

    startScanner();

    return () => {
      if (streamRef.current) {
        streamRef.current.getTracks().forEach(track => track.stop());
      }
    };
  }, []);

  return (
    <div className="admin-container">
      <h2>Admin Page - QR Code Scanner</h2>
      <div className="scanner">
        <video ref={videoRef} width="300" height="300" autoPlay playsInline muted></video>
        <canvas ref={canvasRef} style={{ display: 'none' }}></canvas>
      </div>
      {scannedData && (
        <div className="scanned-data">
          <h3>Scanned Data:</h3>
          <p>{scannedData}</p>
        </div>
      )}
            <Link to="/" className="back-link">
        Back to Home
      </Link>
    </div>
  );
};

export default Admin;
